<template>
  <div>
    <base-headline class="no-top" title="计划信息" />
    <div class="unit-info">
      <p>2024年小微企业帮扶计划名称XXXXXX</p>
      <div class="text split">
        <div class="kv">
          <span class="key">开展年度：</span>
          <span class="val">2024年度</span>
        </div>
        <div class="kv">
          <span class="key">通知文件：</span>
          <span class="val">服务合同附件一.pdf</span>
        </div>
        <div class="kv">
          <span class="key">发布单位：</span>
          <span class="val">测试发布单位</span>
        </div>
        <div class="kv">
          <span class="key">发布日期：</span>
          <span class="val">2024-01-05</span>
        </div>
      </div>
      <div class="text">
        <div class="kv">
          <span class="key ">活动内容：</span>
          <span class="val split-key">永远不要跟别人比幸运，我从来没想过我比别人幸运，我也许比他们更有毅力，在最困难的时候，他们熬不住了，我可以多熬一秒钟、二秒钟</span>
        </div>
      </div>
    </div>
    <base-headline title="企业信息" />
    <div style="padding:16px 24px">
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="unitSelect">企业遴选</el-button>
    </div>
    <el-table class="zwx-table" ref="chooseUnitRef" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="bizFullName" label="行政区划" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unitName" label="单位名称" width="350" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="industryName" label="行业类别" width="220" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="concatPerson" label="职业卫生管理联系人" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="120" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 4, arrowNum: 2, btnNum: 2 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="danger" @click="delRecord(scope.row.uuid)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit()">保存</el-button>
    </fixed-footer>

    <unit-select-Dialog ref="unitSelectDialog" :echoList="dataList" @selectunit="selectunit" />
  </div>
</template>
<script>
import unitSelectDialog from './components/unitSelectDialog.vue'

export default {
  name: 'UnitHelpSelect',
  components: { unitSelectDialog },
  data() {
    return {
      dataList: [],
    }
  },
  created() {
    this.dataList = [
      { rid: 1, uuid: 1, bizFullName: '高淳区1', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      { rid: 2, uuid: 2, bizFullName: '高淳区2', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      // { rid: 3, uuid: 3, bizFullName: '高淳区3', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      // { rid: 4, uuid: 4, bizFullName: '高淳区4', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
      // { rid: 5, uuid: 5, bizFullName: '高淳区5', unitName: '无锡中卫信', industryName: '制造业', phone: '15621133321', concatPerson: '张三' },
    ]
  },
  methods: {
    unitSelect() {
      this.$refs.unitSelectDialog.open(true)
    },
    detail() {
      let data = {
        rid: this.rid,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/msSelectRectifyExpAll-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          this.addForm.rid = data.result.rid
          if (data.type === '00') {
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    save() {
      let data = { ...this.msForm }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/addOrModifyDetectionRectify-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$router.push({ name: 'UnitHelpList', fresh: true })
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    selectunit(list) {
      this.dataList = list
    },
    delRecord(uuid) {
      this.dataList = this.dataList.filter(item => item.uuid != uuid)
    },
  },
}
</script>

<style lang="less" scoped>
.unit-info {
  padding: 20px;
  p {
    font-size: 20px;
    color: #191919;
    font-weight: 500;
  }
  .text {
    margin-top: 17px;
    display: flex;
    align-items: center;
    .kv {
      margin-right: 25px;
      font-size: 14px;
      .key {
        color: #3a485e;
      }
      .split-key {
        display: inline-block;
        width: 631px;
        vertical-align: text-top;
      }
      .val {
        color: #191919;
      }
    }
  }
  .split {
    .kv {
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 12px;
        background: #d8d8d8;
        position: absolute;
        right: -13px;
        top: 3px;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
}
</style>
