var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-headline", {
        staticClass: "no-top",
        attrs: { title: "计划信息" }
      }),
      _vm._m(0),
      _c("base-headline", { attrs: { title: "企业信息" } }),
      _c(
        "div",
        { staticStyle: { padding: "16px 24px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { icon: "el-icon-plus" },
              on: { click: _vm.unitSelect }
            },
            [_vm._v("企业遴选")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "chooseUnitRef",
          staticClass: "zwx-table",
          attrs: {
            "row-key": "rid",
            data: _vm.dataList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "bizFullName",
              label: "行政区划",
              width: "120",
              "header-align": "center",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unitName",
              label: "单位名称",
              width: "350",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "industryName",
              label: "行业类别",
              width: "220",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "concatPerson",
              label: "职业卫生管理联系人",
              width: "200",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              label: "联系电话",
              width: "120",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": _vm.$system.calMinWidth({
                wordNum: 4,
                arrowNum: 2,
                btnNum: 2
              }),
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.delRecord(scope.row.uuid)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c("unit-select-Dialog", {
        ref: "unitSelectDialog",
        attrs: { echoList: _vm.dataList },
        on: { selectunit: _vm.selectunit }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unit-info" }, [
      _c("p", [_vm._v("2024年小微企业帮扶计划名称XXXXXX")]),
      _c("div", { staticClass: "text split" }, [
        _c("div", { staticClass: "kv" }, [
          _c("span", { staticClass: "key" }, [_vm._v("开展年度：")]),
          _c("span", { staticClass: "val" }, [_vm._v("2024年度")])
        ]),
        _c("div", { staticClass: "kv" }, [
          _c("span", { staticClass: "key" }, [_vm._v("通知文件：")]),
          _c("span", { staticClass: "val" }, [_vm._v("服务合同附件一.pdf")])
        ]),
        _c("div", { staticClass: "kv" }, [
          _c("span", { staticClass: "key" }, [_vm._v("发布单位：")]),
          _c("span", { staticClass: "val" }, [_vm._v("测试发布单位")])
        ]),
        _c("div", { staticClass: "kv" }, [
          _c("span", { staticClass: "key" }, [_vm._v("发布日期：")]),
          _c("span", { staticClass: "val" }, [_vm._v("2024-01-05")])
        ])
      ]),
      _c("div", { staticClass: "text" }, [
        _c("div", { staticClass: "kv" }, [
          _c("span", { staticClass: "key" }, [_vm._v("活动内容：")]),
          _c("span", { staticClass: "val split-key" }, [
            _vm._v(
              "永远不要跟别人比幸运，我从来没想过我比别人幸运，我也许比他们更有毅力，在最困难的时候，他们熬不住了，我可以多熬一秒钟、二秒钟"
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }